<template>
  <div
    ref="parent"
    class="d-flex st-history relative d-flex flex-column white--text"
  >
    <div
      class="st-history__close d-flex align-center justify-center"
      @click="$emit('close')"
    >
      <img
        loading="lazy"
        src="@/assets/images/svg/close_w.svg"
        alt="close"
      >
    </div>
    <p class="st-history__title headline-3 text-center">
      История проверок
    </p>
    <div
      v-if="!history?.length"
      class="st-history__na d-flex flex-column align-center justify-center flex-grow"
    >
      <p class="title-4 text-center">
        У вас еще нет результатов проверки скорости интернета
      </p>
      <button
        class="st-history__na-btn title-4 text-center"
        @click="$emit('checkSpeed')"
      >
        Проверить скорость
      </button>
    </div>
    <div
      v-else
      class="st-history__list"
    >
      {{ history }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted } from 'vue'

const props = defineProps({
  history: {},
})
defineEmits(['close', 'checkSpeed'])

const parent = ref()
const ctx = useNuxtApp()

const scrollTopFn = () => {
  parent.value.scrollTo(0, 0)
  window.removeEventListener('resize', scrollTopFn)
}
onMounted(() => {
  nextTick(() => parent.value.scrollTo(0, 0))
  if (ctx.$device.isAndroid) {
    window.addEventListener('resize', scrollTopFn)
  }
})

onBeforeUnmount(async () => {
  if (ctx.$device.isAndroid) {
    window.removeEventListener('resize', scrollTopFn)
  }
})
</script>

<style scoped lang="scss">
.st-history {
  width: 804px;
  padding: 24px;
  max-width: 100%;
  border-radius: 16px;
  background: #232436;
  max-height: 80vh;
  height: 80vh;
  @media (max-width: getBreakpoint(tablet)) {
    padding: 16px 16px 12px;
    margin-top: auto;
    border-radius: 12px 12px 0 0;
  }
  &__close {
    position: absolute;
    right: 24px;
    top: 24px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    cursor: pointer;
    background: #2B2D42;
    img {
      opacity: .7;
      transition: opacity .24s;
      width: 24px;
      height: 24px;
    }

    &:hover {
      img {
        opacity: 1;
      }
    }
  }
  &__title {
    margin-bottom: 28px;
  }

  &__na {
    gap: 16px;
    max-width: 358px;
    margin-left: auto;
    margin-right: auto;

    &-btn {
      color: #4693FF;
      padding: 15px;
      border-radius: 36px;
      border: 1px solid currentColor;
      width: 100%;
    }
  }

}
</style>
