<template>
  <div
    class="p-benefits"
    :class="{ 'black--text': dark }"
  >
    <div class="container">
      <p class="p-benefits__title text-center fwm">
        Почему стоит выбрать {{ provider.provider.name }}
      </p>
      <div class="p-benefits__wrap d-flex justify-between">
        <div
          v-for="(item, i) in benefits"
          :key="'benefit' + i"
          class="p-benefits__item d-flex flex-column align-center"
        >
          <img
            loading="lazy"
            :src="'https://' + mainStore.domain + item.icon_img"
            :alt="item.title"
          >
          <p class="text-center">
            {{ item.title }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useMainStore } from '~/store/main/main.store'

defineProps({
  benefits: {},
  dark: {},
})

const mainStore = useMainStore()
const provider = computed(() => mainStore.getDomainConfig)
</script>

<style scoped lang="scss">
.p-benefits {
  padding: 32px 0;
  &:not(.black--text) {
    color: var(--font_offer);
  }

  &__title {
    font-size: fontSize(headline-7);
    line-height: 1.4;
    margin-bottom: 32px;
    @media (max-width: getBreakpoint(tablet)) {
      font-size: fontSize(headline-8);
      margin-bottom: 16px;
    }
    @media (max-width: getBreakpoint(mobile-lg)) {
      font-size: 17px;
    }
    @media (max-width: getBreakpoint(mobile-md)) {
      font-size: fontSize(title);
      line-height: 1.5;
    }
  }

  &__wrap {
    @media (max-width: getBreakpoint(tablet)) {
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }

  &__item {
    justify-content: space-between;
    img {
      margin-bottom: 8px;
      height: 40px;
    }
    @media (max-width: getBreakpoint(tablet)) {
      img {
        height: 30px;
      }
      justify-content: flex-start;
      flex: 0 0 calc(33.333% - 16px);
      margin-bottom: 16px;
    }
    @media (max-width: 420px) {
      flex: 0 0 calc(50% - 12px);
    }
    @media (max-width: getBreakpoint(mobile-lg)) {
      font-size: 15px;
    }
    @media (max-width: getBreakpoint(mobile-md)) {
      font-size: fontSize(paragraph);
    }
  }
  @media (max-width: getBreakpoint(tablet)) {
    padding: 24px 0 8px;
  }
}
</style>
