<template>
  <div
    v-if="false"
    class="t-selection d-flex align-end justify-between blue--bg"
  >
    <div class="t-selection__wrap d-flex flex-column">
      <p class="headline-2 t-selection__title">
        Автоматический подбор самого выгодного интернета
      </p>
      <span class="title-5 gray-b--text t-selection__subtitle">Наш подборщик найдет лучшие тарифные планы по указанным вами параметрами - параметрам за 4 шага</span>
    </div>
    <d-btn
      color="primary"
      class=" t-selection__btn"
      @click="openModal"
    >
      Подобрать выгодный тариф
    </d-btn>
  </div>
</template>

<script setup lang="ts">
const ctx = useNuxtApp()

const openModal = () => {
  ctx.$event('quizDialog', true)
}
</script>

<style scoped lang="scss">
.t-selection {
  padding: 40px;
  border-radius: 16px;
  border: 1px solid color(gray-darker);
  margin-bottom: 48px;

  @media (max-width: getBreakpoint(desktop)) {
    flex-direction: column;
    align-items: flex-start;
  }
  @media (max-width: getBreakpoint(tablet)) {
    padding: 24px;
  }
  @media (max-width: getBreakpoint(mobile-md)) {
    padding: 16px;
  }

  &__wrap {
    max-width: 500px;
    @media (max-width: getBreakpoint(desktop)) {
      max-width: unset;
    }
  }

  &__title {
    margin-bottom: 4px;
  }
  &__subtitle {
    //margin-bottom: 16px;
    @media (max-width: getBreakpoint(desktop)) {
      margin-bottom: 16px;
    }
  }
  &__btn {
    height: 44px;
    @media (max-width: getBreakpoint(tablet)) {
      width: 100%;
      max-width: 400px;
    }
    @media (max-width: getBreakpoint(mobile-lg)) {
      height: 42px;
    }
    @media (max-width: getBreakpoint(mobile-md)) {
      height: 40px;
    }
  }
}
</style>
