<template>
  <div
    ref="serviceBtns"
    class="service-btns d-flex"
  >
    <div class="service-btns__wrap">
      <div
        class="d-inline-flex"
        style="width: max-content"
      >
        <button
          v-for="(btn, i) in btns.filter((el) => el)"
          :key="'service-btn' + (i + 1)"
          :data-test="`${btn.slug
            .toString()
            .toLowerCase()
            .replace(/,/g, '_')}-smart-filter`"
          class="service-btns__btn"
          :class="{
            active:
              btn
              && btn.value
              && btn.value.join() === tariff.sort((a, b) => a - b).join(),
          }"
          @click="$emit('selectTariffs', btn.value)"
        >
          {{ btn && btn.title }}
        </button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
defineProps({
  btns: {},
  tariff: {},
})

const serviceBtns = ref()

onMounted(() => {
  const button = serviceBtns.value.getElementsByClassName(
    'service-btns__btn active',
  )[0]
  if (button) {
    const buttonRect = button.getBoundingClientRect()
    const buttonLeft
      = buttonRect.left - window.innerWidth + button.offsetWidth
    serviceBtns.value.scrollLeft = buttonLeft
  }
})
</script>

<style scoped lang="scss">
.service-btns {
  @media (max-width: getBreakpoint(desktop)) {
    overflow-x: scroll;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
    &::-webkit-scrollbar-thumb {
      display: none;
    }
  }
  &__wrap {
    //background-color: color(white);
    //border-radius: 56px;
    //padding: 2px;
    //border: 1px solid color(gray-light);
    margin-right: auto;
    position: relative;
  }

  &__btn {
    background-color: color(white);
    position: relative;
    //height: 46px;
    flex-shrink: 0;
    padding: 7px 11px;
    border-radius: 56px;
    border: 1px solid transparent;
    font-size: fontSize(paragraph);
    line-height: 1.4;
    &.active {
      border-color: color(primary);
      color: color(primary);
    }
    //@media (max-width: getBreakpoint(tablet)) {
    //  height: 40px;
    //}
    //@media (max-width: getBreakpoint(mobile-lg)) {
    //  height: 32px;
    //}
  }
}
</style>
