import { defineStore } from 'pinia'
import type { CallsPhoneNumbers, CallsTimeSlots } from '~/api'
import getCallsGeneral from '~/composible/getCallsGeneral'

export const useMainStore = defineStore('main', {
  state: () => ({
    interactionsSended: false,
    utmArr: undefined as object[] | undefined,
    wrongAddress: false,
    metrikaId: undefined as string | undefined,
    uaId: undefined as string | undefined,
    providerId: undefined as string | undefined,
    domain: '' as string,
    apiDomain: '' as string,
    mainDomain: '' as string,
    reqHeaders: undefined as object | undefined,
    domainConfig: null as object | null,
    oldClient: false,
    providerClient: undefined as string | number | undefined,
    addressWOTariffs: false,
    segmentation: false,
    diProvider: false,
    disabledPortal: false,
    disabledMobile: false,
    configShowMap: false,
    automation: null as boolean | null,
    quizBtnShow: true,
    btn60Show: false,
    archievedTariffs: false,
    callCenterBusy: false,
    showLoader: false,
    loaderText: undefined as object[] | undefined,
    loaderTime: undefined as number | undefined,
    woFilter: false,
    woConsult: false,
    hideNumber: false,
    automatizationDirect: false,
    reverseAutomatizationTabs: false,
    showGreenBtn: false,
    callNumber: null as CallsPhoneNumbers | null,
    callSlots: null as CallsTimeSlots | null,
    clientIp: undefined as string | undefined,
    timeStamp: [] as any[],
    userAuth: null as any | null,
    saWStreets: false,
    showFast: false,
    hideConnectionTime: false,
    test46: true,
    headerDark: false,
  }),
  actions: {
    async fetchPhoneNumber(providerId?: number) {
      try {
        this.callNumber = await getCallsGeneral({
          provider_id: providerId,
          domain: this.mainDomain,
        })
      }
      catch {
        this.callNumber = await getCallsGeneral({
          provider_id: providerId,
          domain: this.mainDomain,
        })
      }
    },
    async fetchCallSlots() {
      const timezone_offset = new Date().getTimezoneOffset()
      try {
        this.callSlots = await $fetch(`${this.apiDomain}/calls/time-slots/`, {
          method: 'GET',
          params: {
            timezone_offset,
          },
        })
      }
      catch {}
    },
    async getCallCenterStatus() {
      try {
        const res = await $fetch(`${this.apiDomain}/calls/status/`, {
          method: 'GET',
          params: {},
        })
        this.callCenterBusy = res.availability !== 'AVAILABLE'
      }
      catch (e) {
        console.log('getCallCenterStatus')
        console.log('domain: ' + this.apiDomain)
        console.error(e)
      }
    },
  },
  getters: {
    getInteractionsSended: state => state.interactionsSended,
    getUtmArr: state => state.utmArr,
    getWrongAddress: state => state.wrongAddress,
    getMetrikaId: state => state.metrikaId,
    getUaId: state => state.uaId,
    getProviderId: state => state.providerId,
    getDomain: state => state.domain,
    getMainDomain: state => state.mainDomain,
    getReqHeaders: state => state.reqHeaders,
    getDomainConfig: state => state.domainConfig,
    getOldClient: state => state.oldClient,
    getProviderClient: state => state.providerClient,
    getAddressWOTariffs: state => state.addressWOTariffs,
    getSegmentationActive: state => state.addressWOTariffs || state.oldClient,
    getSegmentation: state => state.segmentation,
    getDiProvider: state => state.diProvider,
    getConfigShowMap: state => state.configShowMap,
    getAutomation: state => state.automation,
    getQuizBtnShow: state => state.quizBtnShow,
    getBtn60Show: state => state.btn60Show && !state.archievedTariffs && !state.callCenterBusy,
    getWoConsult: state => state.woConsult,
    getHideNumber: state => state.hideNumber,
    getAutomatizationDirect: state => state.automatizationDirect,
    getReverseAutomatizationTabs: state => state.reverseAutomatizationTabs,
    getShowGreenBtn: state => !state.callCenterBusy,
    getWoFilter: state => state.woFilter,
    getLoader: state => state.showLoader,
    getLoaderText: state => state.loaderText,
    getLoaderTime: state => state.loaderTime,
    getClientIp: state => state.clientIp,
    getCallNumber: state => state.callNumber,
    getCallSlots: state => state.callSlots,
    getUserAuth: state => state.userAuth,
    getSaWStreets: state => state.saWStreets,
    getShowFast: state => state.showFast,
    getHideConnectionTime: state => state.hideConnectionTime,
    getTest46: state => state.test46,
    getHeaderDark: state => state.headerDark,
  },
})
