<template>
  <div
    ref="parent"
    class="d-flex st-servers relative d-flex flex-column white--text"
  >
    <div
      class="st-servers__close d-flex align-center justify-center"
      @click="$emit('close')"
    >
      <img
        loading="lazy"
        src="@/assets/images/svg/close_w.svg"
        alt="close"
      >
    </div>
    <p class="st-servers__title headline-3 text-center">
      Выбрать сервер
    </p>
    <div class="st-servers__search d-flex">
      <input
        v-model="search"
        type="text"
        placeholder="Найти сервер"
      >
    </div>
    <button
      class="st-servers__auto"
      @click="$emit('selectDefault')"
    >
      Автоматическое определение
    </button>
    <div class="st-servers__list d-flex flex-column">
      <button
        v-for="server in hostsArr"
        :key="server.id"
        class="st-servers__item d-flex flex-column"
        :disabled="server.isDisabled"
        @click="$emit('selectServer', server)"
      >
        <span class="st-servers__item-name">
          {{ server.city }}
        </span>
        <span class="st-servers__item-info gray-inactive--text subhead-3">
          {{ server.name }} - {{ server.distance }} км
        </span>
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted } from 'vue'

const props = defineProps({
  hosts: {},
})
defineEmits(['close', 'selectDefault', 'selectServer'])

const parent = ref()
const ctx = useNuxtApp()
const search = ref('')

const hostsArr = computed(() => props.hosts?.filter(el => search.value ? (el.name?.toLowerCase().includes(search.value.toLowerCase()) || el.city?.toLowerCase().includes(search.value.toLowerCase())) : el))

const scrollTopFn = () => {
  parent.value.scrollTo(0, 0)
  window.removeEventListener('resize', scrollTopFn)
}
onMounted(() => {
  nextTick(() => parent.value.scrollTo(0, 0))
  if (ctx.$device.isAndroid) {
    window.addEventListener('resize', scrollTopFn)
  }
})

onBeforeUnmount(async () => {
  if (ctx.$device.isAndroid) {
    window.removeEventListener('resize', scrollTopFn)
  }
})
</script>

<style scoped lang="scss">
.st-servers {
  width: 640px;
  padding: 24px;
  max-width: 100%;
  border-radius: 16px;
  background: #232436;
  max-height: 80vh;
  height: 80vh;
  @media (max-width: getBreakpoint(tablet)) {
    padding: 16px 16px 12px;
    margin-top: auto;
    border-radius: 12px 12px 0 0;
  }
  &__close {
    position: absolute;
    right: 24px;
    top: 24px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    cursor: pointer;
    background: #2B2D42;
    img {
      opacity: .7;
      transition: opacity .24s;
      width: 24px;
      height: 24px;
    }

    &:hover {
      img {
        opacity: 1;
      }
    }
  }
  &__title {
    margin-bottom: 28px;
  }
  &__search {
    margin-bottom: 12px;
    position: relative;
    &:before {
      content: '';
      position: absolute;
      left: 12px;
      top: 50%;
      transform: translateY(-50%);
      width: 20px;
      height: 20px;
      background-color: color(gray-inactive);
      mask-position: center;
      mask-repeat: no-repeat;
      flex-shrink: 0;
      mask-image: url('@/assets/images/svg/search-thin.svg');
      mask-size: 20px;
    }
    input {
      width: 100%;
      background: #2B2D42;
      color: color(white);
      padding: 12px 12px 12px 36px;
      border-radius: 12px;
      &::placeholder {
        color: color(gray-inactive);
      }
    }
  }

  &__auto {
    color: #4da1ff;
    margin-bottom: 12px;
    transition: color .24s;
    margin-right: auto;
    &:hover {
      color: #5cc2ff;
    }
    &:before {
      content: '';
      width: 20px;
      height: 20px;
      mask-size: 20px;
      display: inline-block;
      background-color: currentColor;
      mask-position: center;
      mask-repeat: no-repeat;
      flex-shrink: 0;
      mask-image: url('@/assets/images/svg/location.svg');
      margin-bottom: -5px;
    }
  }
  &__list {
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 4px;
    }
    &::-webkit-scrollbar-track {
      background: #2B2D42;
      border-radius: 20px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: color(gray-inactive);
      border-radius: 20px;
    }
  }

  &__item {
    padding-top: 12px;
    padding-bottom: 12px;
    transition: background .24s;

    &:hover {
      background: rgba(255, 255, 255, .05);
    }
    &:not(:last-child) {
      border-bottom: 1px solid #3B3D4D;
    }

    &-name {
      margin-bottom: 4px;
    }
  }

}
</style>
