<template>
  <div class="rate-line">
    <p class="d-flex justify-between subhead-3">
      <span>{{ rateTitle }}</span><span>{{ rateValue ? rateValue.toFixed(1) : "" }}</span>
    </p>
    <div class="rate-line__value relative">
      <div
        class="rate-line__gradient"
        :style="'width:' + (100 / 5) * rateValue + '%;'"
      />
    </div>
  </div>
</template>

<script lang="ts">
export default defineComponent({
  name: 'ReviewsRateLine',
  props: {
    rateTitle: {
      type: String,
    },
    rateValue: {},
  },
})
</script>

<style scoped lang="scss">
.rate-line {
  max-width: 215px;
  margin-bottom: 12px;

  &__value {
    background: color(gray-light);
    border-radius: 10px;
    height: 4px;
  }

  &__gradient {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    background: color(gradient);
    border-radius: 10px;
  }
}
</style>
