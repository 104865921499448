<template>
  <article class="pe-small d-flex flex-column">
    <div class="pe-small__header d-flex">
      <div class="pe-small__title d-flex flex-column">
        <img
          :alt="provider.provider.name"
          :src="/.*\.svg/.test(provider?.provider?.logoT?.unoptimized)
            ? provider?.provider?.logoT?.unoptimized
            : provider?.provider?.logoT?.url"
          itemprop="image"
          loading="lazy"
        >
        <span class="gray-dark--text">{{ provider.provider.name }}</span>
      </div>
    </div>
    <a
      v-if="about && about.contact && about.contact.phone"
      class="pe-small__phone secondary-dark--text"
      :href="'tel:' + about.contact.phone"
    >{{ about.contact.display_phone }}</a>
    <div class="pe-small__actions d-flex">
      <button
        class="d-btn d-btn--large d-btn--white-primary flex-grow pe-small__actions-tariffs fwm"
        @click="goToSite"
      >
        Сайт провайдера
      </button>
    </div>
  </article>
</template>

<script setup lang="ts">
const props = defineProps({
  provider: {},
})

const goToSite = () => {
  window.open(props.provider.provider.siteUrl, '_blank')
}
</script>

<style scoped lang="scss">
.pe-small {
  width: 264px;
  background: color(white);
  border: 1px solid color(gray-darker);
  border-radius: 16px;
  padding: 15px;
  transition: box-shadow 0.24s;
  &:hover {
    box-shadow: 0px 0px 18px rgba(3, 5, 32, 0.15);
  }
  @media (max-width: getBreakpoint(tablet)) {
    border-radius: 12px;
    width: 198px;
    padding: 15px 15px 11px;
  }
  @media (max-width: getBreakpoint(mobile-lg)) {
    width: 185px;
  }
  @media (max-width: getBreakpoint(mobile-md)) {
    width: 162px;
    padding: 11px;
  }

  &__header {
    margin-bottom: 12px;

    @media (max-width: getBreakpoint(tablet)) {
      margin-bottom: 8px;
    }
    @media (max-width: getBreakpoint(mobile-lg)) {
      margin-bottom: 7px;
    }
    @media (max-width: getBreakpoint(mobile-lg)) {
      margin-bottom: 5px;
    }
  }

  &__title {
    margin-right: 16px;

    @media (max-width: getBreakpoint(tablet)) {
      margin-right: 8px;
    }
    img {
      max-width: 160px;
      height: 40px;
      object-fit: contain;
      object-position: left;
      margin-bottom: 4px;
      @media (max-width: getBreakpoint(tablet)) {
        width: 120px;
        height: 30px;
      }
      @media (max-width: getBreakpoint(mobile-lg)) {
        width: 108px;
        height: 27px;
      }
      @media (max-width: getBreakpoint(mobile-md)) {
        width: 96px;
        height: 24px;
      }
    }
    a {
      font-size: fontSize(paragraph);
      @media (max-width: getBreakpoint(tablet)) {
        font-size: fontSize(label);
      }
      @media (max-width: getBreakpoint(mobile-lg)) {
        font-size: 11px;
      }
      @media (max-width: getBreakpoint(mobile-md)) {
        font-size: fontSize(small);
      }
      &:before {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }
  &__rate {
    margin-top: 8px;
    margin-bottom: auto;
    margin-left: auto;

    &-score {
      width: 25px;
      height: 24px;
      border-radius: 4px;
      margin-right: 4px;
      font-size: fontSize(paragraph);

      @media (max-width: getBreakpoint(tablet)) {
        width: 22px;
        height: 22px;
        font-size: fontSize(label);
        line-height: 22px;
      }
      @media (max-width: getBreakpoint(mobile-lg)) {
        width: 21px;
        height: 20px;
        font-size: 11px;
        line-height: 20px;
      }
      @media (max-width: getBreakpoint(mobile-md)) {
        width: 19px;
        height: 18px;
        font-size: fontSize(small);
        line-height: 18px;
      }
    }
    span {
      font-size: fontSize(paragraph);
      @media (max-width: getBreakpoint(tablet)) {
        font-size: fontSize(label);
        line-height: 22px;
      }
      @media (max-width: getBreakpoint(mobile-lg)) {
        font-size: 11px;
        line-height: 20px;
      }
      @media (max-width: getBreakpoint(mobile-md)) {
        font-size: fontSize(small);
        line-height: 18px;
      }
    }
  }

  &__description {
    margin-bottom: 16px;
    font-size: fontSize(paragraph);
    line-height: 1.4;
    text-overflow: ellipsis;
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    @media (max-width: getBreakpoint(tablet)) {
      font-size: fontSize(label);
      margin-bottom: 12px;
    }
    @media (max-width: getBreakpoint(mobile-lg)) {
      font-size: 11px;
      margin-bottom: 11px;
    }
    @media (max-width: getBreakpoint(mobile-md)) {
      font-size: fontSize(small);
      margin-bottom: 9px;
    }
  }

  &__phone {
    margin: 32px auto;
    transition: color 0.24s;
    &:hover {
      color: color(primary);
    }
  }

  &__actions {
    margin-top: auto;
    &-tariffs {
      font-size: fontSize(title);
      height: 44px;
      @media (max-width: getBreakpoint(tablet)) {
        height: 36px;
        font-size: fontSize(paragraph);
      }
      @media (max-width: getBreakpoint(mobile-lg)) {
        height: 34px;
        font-size: 13px;
      }
      @media (max-width: getBreakpoint(mobile-md)) {
        height: 32px;
        font-size: fontSize(label);
      }
    }
    .d-btn--icon {
      @media (max-width: getBreakpoint(tablet)) {
        height: 36px;
        width: 36px;
      }
      @media (max-width: getBreakpoint(mobile-lg)) {
        height: 34px;
        width: 34px;
      }
      @media (max-width: getBreakpoint(mobile-md)) {
        height: 32px;
        width: 32px;
      }
    }
  }
  &__connect {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 2;
    backdrop-filter: blur(1px);
    background-color: rgba(color(white), 0.7);
    border-radius: 16px;

    &-wrap {
      padding: 44px 16px 16px;
      box-shadow: 4px 4px 30px rgba(3, 5, 32, 0.15);
      border-radius: 16px;
    }

    &-close {
      position: absolute;
      top: 12px;
      right: 12px;
      cursor: pointer;

      &:deep(circle) {
        fill: color(gray-light);
      }
    }

    &-title {
      margin-bottom: 16px;
    }

    &-tel {
      font-size: fontSize(headline-6);
      margin-right: auto;
      transition: color 0.24s;
      @media (max-width: getBreakpoint(tablet)) {
        font-size: fontSize(headline-8);
      }
      @media (max-width: getBreakpoint(mobile-lg)) {
        font-size: 17px;
      }
      @media (max-width: getBreakpoint(mobile-md)) {
        font-size: fontSize(paragraph);
      }
      &:hover {
        color: color(primary);
      }
    }
    &-free {
      font-size: fontSize(label);
      @media (max-width: getBreakpoint(tablet)) {
        font-size: 11px;
      }
      @media (max-width: getBreakpoint(mobile-lg)) {
        font-size: fontSize(small);
      }
    }
  }
}
</style>
