
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91_46_46_46url_93NCHZdwjfu7zy_45sN1zaFKj2hjQqxcDWU82U2ToNqdj4YMeta } from "/app/pages/dominternet/[...city]/[...url].vue?macro=true";
import { default as _91slug_93tUIls73mQ3hEBEQ4J37yyjIwTFYFbuSbTrMVszfzlVAMeta } from "/app/pages/dominternet/[...city]/address/[slug].vue?macro=true";
import { default as indexCUqjbvBwLvF9zuBiSG_DRFwSdfSf7sH_45mVX9y3m3OlQMeta } from "/app/pages/dominternet/[...city]/for-house/index.vue?macro=true";
import { default as index7p2J2q8QdNfzNZKNALeaHoLPdhvzfONlu3i8hfbzjJIMeta } from "/app/pages/dominternet/[...city]/providers-not-found/index.vue?macro=true";
import { default as indexESHNPyBCryJeUVCso3iS1ozSjG21WWpGzBwY0EtKzAIMeta } from "/app/pages/dominternet/[...city]/providers-to-address/index.vue?macro=true";
import { default as indexbq8BpuvGoY83mtOx_45_45UPtDTl0Cr5lBtkZw4gCUoKgnQMeta } from "/app/pages/dominternet/[...city]/providers/[provider]/[smartFilter]/index.vue?macro=true";
import { default as indexXe3se_GVKklApqnw_h_feTjK1ggYM9ZYTFOKuNBwKK4Meta } from "/app/pages/dominternet/[...city]/providers/[provider]/index.vue?macro=true";
import { default as index_B15bxfdTpxLnn_Ak1P21A3ahFJjyJ90am0j3lHJ2YEMeta } from "/app/pages/dominternet/[...city]/providers/[provider]/reviews/index.vue?macro=true";
import { default as _91id_934xb8gpeeUTeH0VwjZRhzRXTc8zu5ouwourJvFFnJCIsMeta } from "/app/pages/dominternet/[...city]/providers/[provider]/tariffs/[id].vue?macro=true";
import { default as indexwUK_45cTGZ4YioRtKqOXRtyNFQKGCzrKPz7TGAvyiqujYMeta } from "/app/pages/dominternet/[...city]/providers/index.vue?macro=true";
import { default as indexeR45Gn_45cza4X9sUsu9D_45kXkVS8VW5wMILcDMmoPETtEMeta } from "/app/pages/dominternet/[...city]/rating/index.vue?macro=true";
import { default as indexMbN7vDQqCLRf1PdXaDeJNkYCgtLxFpmH_MtV5g3R0rAMeta } from "/app/pages/dominternet/[...city]/reviews/index.vue?macro=true";
import { default as index4oTjG_a_4jLLxFmAbdtzR_45xpTDIbEPdHRvre8H4jLFoMeta } from "/app/pages/dominternet/[...city]/streets/[street]/index.vue?macro=true";
import { default as indexyyOOCMuxs24bWiSbJAKdshqChczNkLJWaUeBORwaAHAMeta } from "/app/pages/dominternet/[...city]/streets/index.vue?macro=true";
import { default as _91smartFilter_93MixSkdEzLTJWk7ddqgIqEhsQlUYdfmpqRVvUmJRzTcEMeta } from "/app/pages/dominternet/[...city]/tariffs/[smartFilter].vue?macro=true";
import { default as indexq6irHAxsbxsN0vEDpNYBKySUiK6f1NYW9mPQL7R107MMeta } from "/app/pages/dominternet/[...city]/tariffs/index.vue?macro=true";
import { default as indexrmqFeNIgzX6pvS0DOsdVZwaumGVmXyAo6OsDg7I7qFsMeta } from "/app/pages/dominternet/[...city]/test-content/index.vue?macro=true";
import { default as index1KFxzzxFLdCMLMwwXU8Jmu7djLeDUjGFyatdJJUYufYMeta } from "/app/pages/dominternet/about/[...query]/index.vue?macro=true";
import { default as indexrZpJBgGU66XErYpvpeRmxzGIVc77QQVydDsgNayGLH4Meta } from "/app/pages/dominternet/about/index.vue?macro=true";
import { default as _91article_93jpP25S0OnvI3Ty6_45ssflUHrDrvY4k88_IrFRBE4mSjcMeta } from "/app/pages/dominternet/blog/[article].vue?macro=true";
import { default as _91author_934PCMwNxbU0ScL4xT8czZ6buZ00lJjp_9C32mydO8slEMeta } from "/app/pages/dominternet/blog/author/[author].vue?macro=true";
import { default as _91slug_93d7ogwDToPhxmJaIXhTOqDsY5w0mUF4ZvuRJuVIIjyloMeta } from "/app/pages/dominternet/blog/category/[slug].vue?macro=true";
import { default as indexkWfa7rRItP2onO4MVFXh3P0Nw52cDph9cYsSrmwudP8Meta } from "/app/pages/dominternet/blog/index.vue?macro=true";
import { default as indexsJedtwHMTo6ABefq2v_Gb4kxo4mfHEHdyx1nugQGyAMMeta } from "/app/pages/dominternet/career/index.vue?macro=true";
import { default as indexJu7esyRPRwkeODZv6OQ4UwkyWTt6eKaloroVR8JUegoMeta } from "/app/pages/dominternet/compare/index.vue?macro=true";
import { default as indexP9FsIrS91GozXtjLRT_1xfVI_45mHaJQDUNT15dGxcq7UMeta } from "/app/pages/dominternet/confidentiality/[...query]/index.vue?macro=true";
import { default as indexwlAtQR6dJai7TmVsBAVXHQDIb3XInQ9w5j4kS1q1HS0Meta } from "/app/pages/dominternet/confidentiality/index.vue?macro=true";
import { default as indexKIkYjPjJulV51IUsSNHiLYhWXVSi34QXUE_45udry9icEMeta } from "/app/pages/dominternet/contacts/[...query]/index.vue?macro=true";
import { default as index9QboGPJ0EU8Eyj5STnNUlNL0_FpsczidtK4tdUb39tsMeta } from "/app/pages/dominternet/contacts/index.vue?macro=true";
import { default as indexSrORupoZOaDJPP6CuZ_45IpXQhOEQi7rBihwZWx_PyWywMeta } from "/app/pages/dominternet/downdetector/[slug]/[category]/index.vue?macro=true";
import { default as indexEln5k5o_8KPSt8LxzTD1jUGaB4rb_AoQmwIqlz_45ZFhAMeta } from "/app/pages/dominternet/downdetector/[slug]/index.vue?macro=true";
import { default as index9lC7u4I89cfSMZ96U4mcVCS6R09ynChjxuYm8HyojxAMeta } from "/app/pages/dominternet/downdetector/index.vue?macro=true";
import { default as indexvjPaT8451hQaKfH1K8wrwi8i3pGsGbPJATXEffOIFkAMeta } from "/app/pages/dominternet/health/index.vue?macro=true";
import { default as index56YwV_45HjUd2zC7D67lCZe8GFUTkFnFPd2t5kNC0q3dMMeta } from "/app/pages/dominternet/help/[...query]/index.vue?macro=true";
import { default as index9_45waLWotM_pnVxbnAcntMHqPfkLl3TV7rWue9ub_45V7sMeta } from "/app/pages/dominternet/help/index.vue?macro=true";
import { default as indexwCjLLhvjd8F8CISqVy_kuLhiq7TSm_x9Fh3V2GaUXNwMeta } from "/app/pages/dominternet/index.vue?macro=true";
import { default as indexHY0VkP193yENwmFo0s9hH4hJwkfxgMRLKWkbI8JYMZYMeta } from "/app/pages/dominternet/info/[...query]/index.vue?macro=true";
import { default as index_yr20nUAxvaVTRL62HgIZ5UKlaaxuumV47fccZVTwy8Meta } from "/app/pages/dominternet/info/index.vue?macro=true";
import { default as indexg4cYxe7jGsUHbH2ed1rQrHtSdPit5Sd_45swLo5ygPFnoMeta } from "/app/pages/dominternet/legal/[...query]/index.vue?macro=true";
import { default as indexM9_45nmSE0NQLVNuR62yfQ_9iSjYqIS_45o0EJTo_H2f1nUMeta } from "/app/pages/dominternet/legal/index.vue?macro=true";
import { default as index6eD96tAdJZgSXGIyUUibnxCPKaXZTbUK15UZZPXFS8gMeta } from "/app/pages/dominternet/locations/index.vue?macro=true";
import { default as indexP42_c87K1Fe_45OZXeZwuLmWllGG_kvh0FDIarJJpao7gMeta } from "/app/pages/dominternet/personal/address/[...slug]/index.vue?macro=true";
import { default as index10J5p8pVhahagZ2bAUmNEdS0kTK9MDB_Q37wTwBM7cgMeta } from "/app/pages/dominternet/personal/checkout/index.vue?macro=true";
import { default as indexuKytjy45c_45Qo_FD7uxbHTAm_DGzDItN9LhaNABxYSYMMeta } from "/app/pages/dominternet/personal/complete/index.vue?macro=true";
import { default as _91order_932hedLQ_45LvXdFVjvmEHlGyTV_Hplp4n6SuVDy6lxBZ6oMeta } from "/app/pages/dominternet/personal/order/[order].vue?macro=true";
import { default as indexD9XpP3FyjgS_eApuFlQstXfaKU_45u_45K49b_45eBYE8gabwMeta } from "/app/pages/dominternet/personal/order/index.vue?macro=true";
import { default as indexaJCm9P8cZ3ObptZoFW4I_45u7jzyP27kQXCD3zEer_45n_IMeta } from "/app/pages/dominternet/personal/quiz-results/index.vue?macro=true";
import { default as indexIetURY22BLP0FVzsMM40LMNXU2Fp7E1jyaPWvZIn9s0Meta } from "/app/pages/dominternet/providers-to-address/index.vue?macro=true";
import { default as indexsS5tBgwdyEdJoapBHCI8TL4_0RRM6vTv2DfA0pUJYR8Meta } from "/app/pages/dominternet/providers/[provider]/checkaddress/index.vue?macro=true";
import { default as indexC_uiFI8Dj7y_vMV7VC5zanQBXmW1PUn4Pr_nTfmjsgwMeta } from "/app/pages/dominternet/providers/[provider]/index.vue?macro=true";
import { default as indexlOBifQoM91PTqjqHHc1BXTzIeOIHb_0jozJ3uXDp5BgMeta } from "/app/pages/dominternet/providers/[provider]/reviews/index.vue?macro=true";
import { default as thanksBljCb6amewhVmZSN5iCmQVAPBd3xdxtaNDuEwgWjkjMMeta } from "/app/pages/dominternet/providers/[provider]/thanks.vue?macro=true";
import { default as indexAz0ErmDi_45KB3nDKkmaowd_45BSyWObT4r5XHr9KlVlnE4Meta } from "/app/pages/dominternet/providers/index.vue?macro=true";
import { default as quiz_45complete7M2iy9knBGgW8_mlq5DE_fcktThEuTq8js_45uxJSuK24Meta } from "/app/pages/dominternet/quiz-complete.vue?macro=true";
import { default as indexHy9QCy3w9q_45R0popGfGNW4O0PrGIYnv_45MWpMKQ6vjdsMeta } from "/app/pages/dominternet/rating/index.vue?macro=true";
import { default as index1oiB4qBsmuezPNBhCP6Ro1BTpl5l_12_muLWVWK89AwMeta } from "/app/pages/dominternet/speedtest/index.vue?macro=true";
import { default as index_45GSE359fySU724jKyXIAmGajbJT_45CH0EQEWc_45ebUXG8Meta } from "/app/pages/dominternet/speedtest/rt/index.vue?macro=true";
import { default as testr5wjkZxOYhHgtFF0waWCtm4hjZ3BPX5zgACQTsFM6BsMeta } from "/app/pages/dominternet/speedtest/rt/test.vue?macro=true";
import { default as index9j9KnYXAVQ22G_YcnqPyHdFn8SaWfO_d_WcyMF0fEwgMeta } from "/app/pages/dominternet/subscriptions/[slug]/index.vue?macro=true";
import { default as indexqeRwKcwSea7X5aNHr6Bz_Z_7SCXhdP7StQWMrLh4XAYMeta } from "/app/pages/dominternet/subscriptions/index.vue?macro=true";
export default [
  {
    name: "city-url",
    path: "/:city(.*)*/:url(.*)*",
    meta: _91_46_46_46url_93NCHZdwjfu7zy_45sN1zaFKj2hjQqxcDWU82U2ToNqdj4YMeta || {},
    component: () => import("/app/pages/dominternet/[...city]/[...url].vue")
  },
  {
    name: "city-address-slug",
    path: "/:city(.*)*/address/:slug()",
    meta: _91slug_93tUIls73mQ3hEBEQ4J37yyjIwTFYFbuSbTrMVszfzlVAMeta || {},
    component: () => import("/app/pages/dominternet/[...city]/address/[slug].vue")
  },
  {
    name: "city-for-house",
    path: "/:city(.*)*/for-house",
    component: () => import("/app/pages/dominternet/[...city]/for-house/index.vue")
  },
  {
    name: "city-providers-not-found",
    path: "/:city(.*)*/providers-not-found",
    component: () => import("/app/pages/dominternet/[...city]/providers-not-found/index.vue")
  },
  {
    name: "city-providers-to-address",
    path: "/:city(.*)*/providers-to-address",
    meta: indexESHNPyBCryJeUVCso3iS1ozSjG21WWpGzBwY0EtKzAIMeta || {},
    component: () => import("/app/pages/dominternet/[...city]/providers-to-address/index.vue")
  },
  {
    name: "city-providers-provider-smartFilter",
    path: "/:city(.*)*/providers/:provider()/:smartFilter()",
    component: () => import("/app/pages/dominternet/[...city]/providers/[provider]/[smartFilter]/index.vue")
  },
  {
    name: "city-providers-provider",
    path: "/:city(.*)*/providers/:provider()",
    meta: indexXe3se_GVKklApqnw_h_feTjK1ggYM9ZYTFOKuNBwKK4Meta || {},
    component: () => import("/app/pages/dominternet/[...city]/providers/[provider]/index.vue")
  },
  {
    name: "city-providers-provider-reviews",
    path: "/:city(.*)*/providers/:provider()/reviews",
    component: () => import("/app/pages/dominternet/[...city]/providers/[provider]/reviews/index.vue")
  },
  {
    name: "city-providers-provider-tariffs-id",
    path: "/:city(.*)*/providers/:provider()/tariffs/:id()",
    component: () => import("/app/pages/dominternet/[...city]/providers/[provider]/tariffs/[id].vue")
  },
  {
    name: "city-providers",
    path: "/:city(.*)*/providers",
    component: () => import("/app/pages/dominternet/[...city]/providers/index.vue")
  },
  {
    name: "city-rating",
    path: "/:city(.*)*/rating",
    component: () => import("/app/pages/dominternet/[...city]/rating/index.vue")
  },
  {
    name: "city-reviews",
    path: "/:city(.*)*/reviews",
    component: () => import("/app/pages/dominternet/[...city]/reviews/index.vue")
  },
  {
    name: "city-streets-street",
    path: "/:city(.*)*/streets/:street()",
    component: () => import("/app/pages/dominternet/[...city]/streets/[street]/index.vue")
  },
  {
    name: "city-streets",
    path: "/:city(.*)*/streets",
    component: () => import("/app/pages/dominternet/[...city]/streets/index.vue")
  },
  {
    name: "city-tariffs-smartFilter",
    path: "/:city(.*)*/tariffs/:smartFilter()",
    meta: _91smartFilter_93MixSkdEzLTJWk7ddqgIqEhsQlUYdfmpqRVvUmJRzTcEMeta || {},
    component: () => import("/app/pages/dominternet/[...city]/tariffs/[smartFilter].vue")
  },
  {
    name: "city-tariffs",
    path: "/:city(.*)*/tariffs",
    meta: indexq6irHAxsbxsN0vEDpNYBKySUiK6f1NYW9mPQL7R107MMeta || {},
    component: () => import("/app/pages/dominternet/[...city]/tariffs/index.vue")
  },
  {
    name: "city-test-content",
    path: "/:city(.*)*/test-content",
    component: () => import("/app/pages/dominternet/[...city]/test-content/index.vue")
  },
  {
    name: "about-query",
    path: "/about/:query(.*)*",
    component: () => import("/app/pages/dominternet/about/[...query]/index.vue")
  },
  {
    name: "about",
    path: "/about",
    component: () => import("/app/pages/dominternet/about/index.vue")
  },
  {
    name: "blog-article",
    path: "/blog/:article()",
    component: () => import("/app/pages/dominternet/blog/[article].vue")
  },
  {
    name: "blog-author-author",
    path: "/blog/author/:author()",
    component: () => import("/app/pages/dominternet/blog/author/[author].vue")
  },
  {
    name: "blog-category-slug",
    path: "/blog/category/:slug()",
    component: () => import("/app/pages/dominternet/blog/category/[slug].vue")
  },
  {
    name: "blog",
    path: "/blog",
    component: () => import("/app/pages/dominternet/blog/index.vue")
  },
  {
    name: "career",
    path: "/career",
    meta: indexsJedtwHMTo6ABefq2v_Gb4kxo4mfHEHdyx1nugQGyAMMeta || {},
    component: () => import("/app/pages/dominternet/career/index.vue")
  },
  {
    name: "compare",
    path: "/compare",
    component: () => import("/app/pages/dominternet/compare/index.vue")
  },
  {
    name: "confidentiality-query",
    path: "/confidentiality/:query(.*)*",
    component: () => import("/app/pages/dominternet/confidentiality/[...query]/index.vue")
  },
  {
    name: "confidentiality",
    path: "/confidentiality",
    component: () => import("/app/pages/dominternet/confidentiality/index.vue")
  },
  {
    name: "contacts-query",
    path: "/contacts/:query(.*)*",
    component: () => import("/app/pages/dominternet/contacts/[...query]/index.vue")
  },
  {
    name: "contacts",
    path: "/contacts",
    component: () => import("/app/pages/dominternet/contacts/index.vue")
  },
  {
    name: "downdetector-slug-category",
    path: "/downdetector/:slug()/:category()",
    component: () => import("/app/pages/dominternet/downdetector/[slug]/[category]/index.vue")
  },
  {
    name: "downdetector-slug",
    path: "/downdetector/:slug()",
    component: () => import("/app/pages/dominternet/downdetector/[slug]/index.vue")
  },
  {
    name: "downdetector",
    path: "/downdetector",
    component: () => import("/app/pages/dominternet/downdetector/index.vue")
  },
  {
    name: "health",
    path: "/health",
    meta: indexvjPaT8451hQaKfH1K8wrwi8i3pGsGbPJATXEffOIFkAMeta || {},
    component: () => import("/app/pages/dominternet/health/index.vue")
  },
  {
    name: "help-query",
    path: "/help/:query(.*)*",
    component: () => import("/app/pages/dominternet/help/[...query]/index.vue")
  },
  {
    name: "help",
    path: "/help",
    meta: index9_45waLWotM_pnVxbnAcntMHqPfkLl3TV7rWue9ub_45V7sMeta || {},
    component: () => import("/app/pages/dominternet/help/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/app/pages/dominternet/index.vue")
  },
  {
    name: "info-query",
    path: "/info/:query(.*)*",
    component: () => import("/app/pages/dominternet/info/[...query]/index.vue")
  },
  {
    name: "info",
    path: "/info",
    component: () => import("/app/pages/dominternet/info/index.vue")
  },
  {
    name: "legal-query",
    path: "/legal/:query(.*)*",
    component: () => import("/app/pages/dominternet/legal/[...query]/index.vue")
  },
  {
    name: "legal",
    path: "/legal",
    component: () => import("/app/pages/dominternet/legal/index.vue")
  },
  {
    name: "locations",
    path: "/locations",
    component: () => import("/app/pages/dominternet/locations/index.vue")
  },
  {
    name: "personal-address-slug",
    path: "/personal/address/:slug(.*)*",
    component: () => import("/app/pages/dominternet/personal/address/[...slug]/index.vue")
  },
  {
    name: "personal-checkout",
    path: "/personal/checkout",
    meta: index10J5p8pVhahagZ2bAUmNEdS0kTK9MDB_Q37wTwBM7cgMeta || {},
    component: () => import("/app/pages/dominternet/personal/checkout/index.vue")
  },
  {
    name: "personal-complete",
    path: "/personal/complete",
    meta: indexuKytjy45c_45Qo_FD7uxbHTAm_DGzDItN9LhaNABxYSYMMeta || {},
    component: () => import("/app/pages/dominternet/personal/complete/index.vue")
  },
  {
    name: "personal-order-order",
    path: "/personal/order/:order()",
    meta: _91order_932hedLQ_45LvXdFVjvmEHlGyTV_Hplp4n6SuVDy6lxBZ6oMeta || {},
    component: () => import("/app/pages/dominternet/personal/order/[order].vue")
  },
  {
    name: "personal-order",
    path: "/personal/order",
    meta: indexD9XpP3FyjgS_eApuFlQstXfaKU_45u_45K49b_45eBYE8gabwMeta || {},
    component: () => import("/app/pages/dominternet/personal/order/index.vue")
  },
  {
    name: "personal-quiz-results",
    path: "/personal/quiz-results",
    meta: indexaJCm9P8cZ3ObptZoFW4I_45u7jzyP27kQXCD3zEer_45n_IMeta || {},
    component: () => import("/app/pages/dominternet/personal/quiz-results/index.vue")
  },
  {
    name: "providers-to-address",
    path: "/providers-to-address",
    component: () => import("/app/pages/dominternet/providers-to-address/index.vue")
  },
  {
    name: "providers-provider-checkaddress",
    path: "/providers/:provider()/checkaddress",
    meta: indexsS5tBgwdyEdJoapBHCI8TL4_0RRM6vTv2DfA0pUJYR8Meta || {},
    component: () => import("/app/pages/dominternet/providers/[provider]/checkaddress/index.vue")
  },
  {
    name: "providers-provider",
    path: "/providers/:provider()",
    component: () => import("/app/pages/dominternet/providers/[provider]/index.vue")
  },
  {
    name: "providers-provider-reviews",
    path: "/providers/:provider()/reviews",
    component: () => import("/app/pages/dominternet/providers/[provider]/reviews/index.vue")
  },
  {
    name: "providers-provider-thanks",
    path: "/providers/:provider()/thanks",
    component: () => import("/app/pages/dominternet/providers/[provider]/thanks.vue")
  },
  {
    name: "providers",
    path: "/providers",
    component: () => import("/app/pages/dominternet/providers/index.vue")
  },
  {
    name: "quiz-complete",
    path: "/quiz-complete",
    meta: quiz_45complete7M2iy9knBGgW8_mlq5DE_fcktThEuTq8js_45uxJSuK24Meta || {},
    component: () => import("/app/pages/dominternet/quiz-complete.vue")
  },
  {
    name: "rating",
    path: "/rating",
    component: () => import("/app/pages/dominternet/rating/index.vue")
  },
  {
    name: "speedtest",
    path: "/speedtest",
    component: () => import("/app/pages/dominternet/speedtest/index.vue")
  },
  {
    name: "speedtest-rt",
    path: "/speedtest/rt",
    component: () => import("/app/pages/dominternet/speedtest/rt/index.vue")
  },
  {
    name: "speedtest-rt-test",
    path: "/speedtest/rt/test",
    component: () => import("/app/pages/dominternet/speedtest/rt/test.vue")
  },
  {
    name: "subscriptions-slug",
    path: "/subscriptions/:slug()",
    component: () => import("/app/pages/dominternet/subscriptions/[slug]/index.vue")
  },
  {
    name: "subscriptions",
    path: "/subscriptions",
    component: () => import("/app/pages/dominternet/subscriptions/index.vue")
  }
]